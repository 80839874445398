/* { 
  name: '',
  website: '',
  logo: '',
  description: '',
  socialHandles: [''],
  videos: [],
  longerDescription: '',
  tags:[],
}, */

export const appsServicesAds= [
          { 
            name: 'BCHBull',
            website: 'bchbull.com/',
            logo: 'bchbull.png',
            description: 'BCH Bull is built with AnyHedge, allowing instant on-chain smart contracts to speculate or stabilize Bitcoin Cash agaisnt a number of assets.',
            socialHandles: ['twitter.com/bch_bull','t.me/bchbull','instagram.com/bchbull/'],
            videos: ['https://www.youtube.com/embed/bHKW7AN7H1k'],
            longerDescription: 'BCH BULL is a unique web application that allows users, "Bulls", to take long positions with leverage, or hedge, with Bitcoin Cash against a number of other metals, fiat and crypto assets, all done entirely using smart contracts on the Bitcoin Cash blockchain. The application is exceptional as it is one of the few decentralised and non-custodial applications in operation on a layer 1 UTXO blockchain, thus placing it at the forefront of a new frontier in cryptocurrency technology. ',
            tags:[],
          },
          { 
            name: 'VotePeer',
            website: 'voter.cash/#/',
            logo: 'votepeer.png',
            description: 'VotePeer allows you to rapidly set up, hold a vote, or a poll on top of the Bitcoin Cash blockchain that implements the two option smart contract.',
            socialHandles: ['t.me/buip129','twitter.com/votepeer','linktr.ee/votepeer'],
            videos: ['https://www.youtube.com/embed/NBhE_v6yewo'],
            longerDescription: 'The two option vote smart-contract allows participating in elections, verifying and tallying the result of an election without running a full Bitcoin Cash node. two option vote is fully SPV-client compatible and the votes are non-transferable. No registration is required, use the android app and scan a QR code to log in.',
            tags:[],
          },   
            { 
            name: 'Flipstarter',
            website: 'flipstarter.cash/',
            logo: 'flipstarter.png',
            description: 'Flipstarter provides a way for Peer to Peer crowdfunding  that encourages accountability for projects and fairness for funders.',
            socialHandles: ['t.me/flipstarter','twitter.com/flipstartercash','github.com/flipstarter','gitlab.com/flipstarter'],
            videos: ['https://www.youtube.com/embed/3uboSNTGcnY'],
            longerDescription: 'Anyone can create a flipstarter, anyone can donate!',
            tags:[],
          },
          { 
            name: 'ChainTip',
            website: 'www.chaintip.org/',
            logo: 'chaintip.png',
            description: 'On-Chain tipping. You hold the Keys. Works on Reddit and Github.',
            socialHandles: [],
            videos: [],
            longerDescription: 'Mention chaintip to call the chaintip bot. Chaintip will send you a private message letting you know which address to send Bitcoin Cash to. Use a mobile phone or other wallet to send Bitcoin Cash to the address. Done!',
            tags:[],
          },
          { 
            name: 'CashTags',
            website: 'tags.infra.cash',
            logo: 'cashtags.png',
            description: 'CashTags generates printable QR Codes (Payment URLs) whose value amounts can be specified in fiat (e.g. USD).',
            socialHandles: ['t.me/jimtendo','github.com/developers-cash/cashtags-server'],
            videos: [],
            longerDescription: 'This service generates printable QR Codes (Payment URLs) whose value amounts can be specified in fiat (e.g. USD). When a user scans these QR Codes/Payment URLs into their wallet, the fiat amount specified is converted to its BCH equivalent using Coinbase Exchange Rates. This helps mitigate BCH price volatility relative to fiat. If $1USD is the amount specified on the QR Code, this will always be converted to its BCH equivalent at the time the user scans it, meaning that in real-life self-serve scenarios the QR Codes will not need constant reprinting.',
            tags:[],
          },
          { 
            name: 'Cointree',
            website: 'landing.cointr.ee/',
            logo: 'cointree.png',
            description: 'Add all your crypto addresses, websites and social media sites on your page so people can follow and support you everywhere you are.',
            socialHandles: ['twitter.com/cryptocointree'],
            videos: ['https://www.youtube.com/embed/xprhL1tW2xk'],
            longerDescription: 'No account or login required to send a donation or message. Add your cointree link to your profiles so that people know where they can find you and send you tips.',
            tags:[],
          },
          { 
            name: 'FullStack',
            website: 'fullstack.cash/',
            logo: 'cashstack.png',
            description: 'BCH Blockahin As-A-Service. FullStack provides back end infrastructure, so front end developers can hit the ground running.',
            socialHandles: ['t.me/permissionless_software','github.com/Permissionless-Software-Foundation'],
            videos: [],
            longerDescription: 'Our full stack software allows modern apps to talk to the blockchain, and use permissionless money. This is the buisness logic that makes one app different from another. This is the user interface that end-users get to interact with. This is the layer where front end developers play. ',
            tags:[],
          },
          { 
            name: 'Prompt.cash',
            website: 'prompt.cash',
            logo: 'promptcash.JPG',
            description: 'Bitcoincash payment gateway. Non-custodial. On all devices and platforms!',
            socialHandles: ['twitter.com/CashPrompt'],
            videos: ['https://www.youtube.com/embed/8TIpZW1P_9M'],
            longerDescription: 'From 0 Conf payments, wordpress plugin, and merchant migration from PayPal to BCH... Your money is yours with the Prompt.cash plugin. A fully responsive and easy to use design on mobile devices  and desktop computers.',
            tags:[],
          },
          { 
            name: 'Read.cash',
            website: 'read.cash',
            logo: 'readcash.png',
            description: 'Write and read articles. Tip in BCH.',
            socialHandles: [],
            videos: [],
            longerDescription: '',
            tags:[],
          },
          { 
            name: 'Memo.cash',
            website: 'memo.cash',
            logo: 'memocash.png',
            description: 'Decentralized social network on the BCH blockchain.',
            socialHandles: [],
            videos: [],
            longerDescription: '',
            tags:[],
          },
          { 
            name: 'Onlycoins',
            website: 'onlycoins.com',
            logo: 'onlycoins.JPG',
            description: 'OnlyCoins is a digital content marketplace. It allows creators to sell content, and receive funds directly and instantly, from supporters.',
            socialHandles: ['t.me/onlycoinscom','twitter.com/onlycoinscom','instagram.com/onlycoins_com/'],
            videos: [],
            longerDescription: '',
            tags:['NSFW'],
          },
          { 
            name: 'Cashrain',
            website: 'cashrain.com',
            logo: 'cashrainlogo.png',
            description: 'Create communties to rain BCH on your members!',
            socialHandles: [],
            videos: [],
            longerDescription: 'Created by Kim.com - Users can create communties that rain BCH split at random on their members.',
            tags:['BETA'],
          },
          { 
            name: 'Unspent',
            website: 'unspent.cash',
            logo: 'unspent.png',
            description: 'Decentralized finance using unspent transaction unlocking script. Open source, on-chain, running nativiely & directly on Bitcoin Cash (BCH).',
            socialHandles: ['github.com/2qx/unspent'],
            videos: [],
            longerDescription: `This app is for saving money for regular distribution over a long period of time. The core feature is a trustless irrevocable contract that anyone can customize, publish and fund themselves.
    
            \nThere are no fiat prices, no markets. Nothing is traded. All contracts are single party. Contracts do not co-mingle funds, nor do they rely on outside data. Contracts exist on the base layer of the Bitcoin Cash blockchain, and can continue to function without this webpage. \n
            Anyone can put their own money on a contract they created and get the same money back over time. This app allows anyone to do the math to calculate the locking code. This app does not provide any services.`,
            tags:[],
          },
          { 
            name: 'ScanToPay',
            website: 'scantopay.cash/#/',
            logo: 'scantopay.png',
            description: ' BitcoinCash Paper Terminals - A quick, easy, low-cost, low-maintenance way to start accepting BCH',
            socialHandles: ['gitlab.com/scantopay.cash','t.me/jimtendo','read.cash/@jimtendo/scantopaycash-ca4fa11c'],
            videos: [],
            longerDescription: `A terminal is printed and configured by scanning the Terminal QR Code for the first time.  
            \n Then, when a Customer wants to pay using Bitcoin Cash, the staff member scans the terminal QR Code using their own phone and sets the amount due. 
            \n Customer then opens their Bitcoin Cash Wallet Application, scans the "Scan to Pay" QR Code on the counter and confirms the payment. 
            \n The Terminal will then show that the Transaction and the amount requested has been received successfully along with a nice "ding!" noise. `,
            tags:[],
          },
          { 
            name: 'BCHouse',
            website: 'bchouse.fly.dev/',
            logo: 'bchouse.png',
            description: 'Social networking and crowdfunding with BitcoinCash *Development preview mode*.',
            socialHandles: ['t.me/bitcoincashouse'],
            videos: [],
            longerDescription: 'Created by Sahid Miller, a twitter clone with BCH intergration and features built on top. Currently in development.',
            tags:[],
          },
          { 
            name: "SuperBCHat",
            website: "superbchat.fly.dev/",
            logo: 'bch.png',
            description: 'Sign up to use this web app to receive superchats in your stream.',
            socialHandles: ['github.com/vulkan0n/superbchat'],
            videos: [],
            longerDescription: 'Sign up to create your superbchat and start receiving donations, or Log in to access your received messages and get your alert URL to set up in OBS',
            tags:[],
          },
          { 
            name: 'Cash Accounts',
            website: 'cashaccount.info',
            logo: 'cashaccount.png',
            description: 'Cash Accounts allows for human readable account names that are easy to share in conversation',
            socialHandles: ['discord.com/invite/9kACN9t','gitlab.com/cash-accounts/specification'],
            videos: [],
            longerDescription: 'Bitcoin Cash needs an alias/address system that is designed to meet the expectations of normal users.',
            tags:[],
          }, 
          { 
            name: 'bitrequest',
            website: 'bitrequest.io/',
            logo: 'nologo.png',
            description: 'Create and share payment requests for cryptocurrencies.',
            socialHandles: ['twitter.com/bitrequest','reddit.com/r/bitrequest/', 'youtube.com/channel/UCwrbrnGg82FQdVQqCSEBmug/featured'],
            videos: ['https://www.youtube.com/embed/DmRi-sXgYmA?si=2uVnjLFItCm-4ewR'],
            longerDescription: 'Create and share payment requests for cryptocurrencies. Point of Sale. Send cryptocurrency payment requests to your friends.',
            tags:[],
          }, 
          { 
            name: 'CashStamps',
            website: 'https://stamps.cash/#/',
            logo: 'cashstamps.png',
            description: 'CashStamps are easily redeemable Bitcoin Cash wallets that can be used for gifting BCH with the option to reclaim any unused stamps. ',
            socialHandles: ['t.me/stampscash','twitter.com/cashstamps', 'github.com/harvmaster/CashStamps'],
            videos: [],
            longerDescription: `CashStamps are easily redeemable Bitcoin Cash wallets that can be used for gifting BCH with the option to reclaim any unused stamps.
            Instructions:
            
                1. Enter the value, currency and quantity of stamps you want to create.
                2. Select a template (or create your own with the Edit Button).
                3. Click the fund button and scan the QR Code with your wallet.
                4. Print the stamps and give them to your friends, family, or customers.
                5. Click Reclaim to send any unclaimed stamps back to your own wallet.`,
            tags:[],
          }, 
          { 
            name: 'NanoGPT',
            website: 'nano-gpt.com',
            logo: 'nanogpt.png',
            description: 'Access the top LLMs (ChatGPT, Claude, Gemini) plus image generation without subscription. Minimum deposit $0.10.',
            socialHandles: ['twitter.com/NanoGPTcom', 'discord.gg/KaQt8gPG6V'],
            videos: [],
            longerDescription: `Access the top LLMs (ChatGPT, Claude, Gemini) plus image generation without subscription. Minimum deposit $0.10. \n
            Note: Supports BCH deposits, but automatically converts them into Nano.`,
            tags:[],
          }, 
          // Add more ads in the Electronics category as needed
        ]


export default appsServicesAds